import { MenuCreateOptionInterface } from 'kernel/types/module'
import pageConstants from 'modules/page/config/pageConstants'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_PAGE']),
          label: this.$t('page.page-category'),
          icon: 'fa fa-tags',
          group: pageConstants.types.map(type => ({
            label: this.$t(`page.type.${type}`),
            route: { name: `page-category-${type}` },
          })),
        },
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_PAGE']),
          label: this.$t('page.page'),
          icon: 'fa fa-info-circle',
          group: pageConstants.types.map(type => ({
            label: this.$t(`page.type.${type}`),
            route: { name: `page-${type}` },
          })),
        },
      ],
    }
  }
}

export default () => (new menu()).get()
